import { Link, Skeleton } from '@mui/material';
import type { FC } from 'react';
import usePromise from 'react-use-promise';
import { useCache } from '../../providers/cache.js';
import { seriesSlugGroup } from '../../utils/url.js';

export const EventSeriesEntity: FC<{ eventSeriesId: string; link?: boolean }> =
  ({ eventSeriesId, link }) => {
    const { eventSeries } = useCache();
    const [data, error, state] = usePromise(
      () => eventSeries.get(eventSeriesId),
      [eventSeriesId],
    );

    if (state === 'pending') return <Skeleton variant="rounded" />;
    if (state === 'rejected') return <>Error: {error.message}</>;
    if (!data) return null;

    if (!link) {
      return <>{data.title}</>;
    }

    return (
      <Link href={`#/series/${seriesSlugGroup(data)}/${data.slug}`}>
        {data.title}
      </Link>
    );
  };

import { EmojiEvents as Icon } from '@mui/icons-material';
import type { SxProps, Theme } from '@mui/material';
import { Chip, useMediaQuery } from '@mui/material';
import type { FC } from 'react';

export const CompetitionChip: FC<{ sx?: SxProps }> = ({ sx }) => (
  <Chip
    color="success"
    label={
      !useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
        ? 'Competition'
        : 'Comp'
    }
    size="small"
    icon={<Icon />}
    sx={sx}
  />
);
